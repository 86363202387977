import * as React from 'react'
import styled from '@emotion/styled'
import Copyright from '../Copyright'
import { navigate } from 'gatsby'
import { openEtsy, openInstagram } from '../../utils'
import Transition from 'react-transition-group/Transition'
import { LIGHT_BLUE } from './Nav/Burger'
import { ITransitionHeights } from '../../lib'

export const NAV_BG = '#f7614a'

const duration = 100

interface IWrapperProps {
  state: string
}

interface IProps {
  toggle: () => void
  isOpen: boolean
}

const transitionHeights: ITransitionHeights = {
  entered: '0%',
  exited: '-100%'
}

const Wrapper = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  height: 400px;
  width: 100vw;
  z-index: 9998;
  background-color: ${NAV_BG};
  color: ghostwhite;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-top: 82px;
  padding-bottom: 10px;
  transition: transform ${duration}ms ease-in-out;
  transform: translateY(${(p: IWrapperProps) => transitionHeights[p.state]});
  a {
    color: ${LIGHT_BLUE};
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
  @media (min-width: 100vh) {
    height: auto;
  }
  @media (min-width: 640px) {
    display: none;
  }
`
const Nav = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Link = styled('a')`
  color: ghostwhite;
  margin: 0 10px;
  text-align: center;
  font-size: 24px;
  padding: 7px 0px;
`

const Design = () => <Link onClick={() => navigate('/#Design')}>Design</Link>
const Illustration = () => <Link onClick={() => navigate('/#Illustration')}>Illustration</Link>
const About = () => <Link onClick={() => navigate('/#about')}>About</Link>
const Shop = () => <Link onClick={openEtsy}>Shop</Link>
const Insta = () => <Link onClick={openInstagram}>Instagram</Link>
const Contact = () => <Link href="mailto:ropersyd@gmail.com">Contact Me</Link>

const MobileNav: React.FC<IProps> = ({ toggle, isOpen }) => (
  <Transition in={isOpen} timeout={duration}>
    {(state) => (
      <Wrapper onClick={toggle} {...{ state }}>
        <Nav>
          <Design />
          <Illustration />
          <About />
          <Shop />
          <Insta />
          <Contact />
        </Nav>
        <Copyright />
      </Wrapper>
    )}
  </Transition>
)

export default MobileNav

import React from 'react'
import css from '@emotion/css'
import styled from '@emotion/styled'

export const LIGHT_BLUE = '#b7f9ff'

interface IProps {
  isActive: boolean
  toggle: () => void
}

interface IWrapperProps {
  isActive: boolean
}

const Wrapper = styled('div')`
  width: 38px;
  height: 38px;
  display: flex;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  grid-area: nav;
  @media (min-width: 640px) {
    display: none;
  }
  span {
    display: block;
    position: absolute;
    height: 6px;
    width: 38px;
    background: ${(props: IWrapperProps) => (props.isActive ? LIGHT_BLUE : 'white')};
    border-radius: 1px;
    transform: rotate(0deg);
    transition: 300ms ease-in-out;
    &:nth-of-type(1) {
      top: 5px;
    }
    &:nth-of-type(2) {
      top: 16px;
    }
    &:nth-of-type(3) {
      top: 27px;
    }
  }
  ${({ isActive }: { isActive: boolean }) => isActive && open};
`
const open = css`
  span {
    &:nth-of-type(1) {
      transform: rotate(-45deg);
      top: 16px;
    }
    &:nth-of-type(2) {
      width: 0%;
      left: 50%;
    }
    &:nth-of-type(3) {
      transform: rotate(45deg);
      top: 16px;
    }
  }
`

const Burger: React.FC<IProps> = ({ isActive, toggle }) => (
  <Wrapper {...{ isActive }} onClick={toggle}>
    <span />
    <span />
    <span />
  </Wrapper>
)

export default Burger

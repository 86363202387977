import * as React from 'react'
import styled from '@emotion/styled'

const StyledLayoutMain = styled('main')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

interface ILayoutMainProps {
  className?: string
}

const LayoutMain: React.FC<ILayoutMainProps> = ({ children, className }) => (
  <StyledLayoutMain className={className} id="page-wrap">
    {children}
  </StyledLayoutMain>
)

export default LayoutMain

import { dimensions } from './variables'
import { css, keyframes } from '@emotion/react'

const shimmer = keyframes`
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
`

export const getEmSize = (size: number) => size / dimensions.fontSize.regular

export const shimmerOnHover = (time: Number) => css`
  &:hover {
    mask-image: linear-gradient(-75deg, rgba(0, 0, 0, 0.6) 30%, #000 50%, rgba(0, 0, 0, 0.6) 70%);
    mask-size: 200%;
    animation: ${shimmer} ${time.toString()}s infinite;
  }
`

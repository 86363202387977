import React from 'react'
import Helmet from 'react-helmet'

interface IProps {
  config: {
    title: string
    description: string
    siteImage?: string
    siteUrl?: string
  }
}
const SEO: React.FC<IProps> = ({ config }) => {
  // const schemaOrgJSONLD = JSON.stringify({
  //   '@context': 'http://schema.org',
  //   '@type': 'WebSite',
  //   url: config.siteUrl,
  //   name: config.title,
  //   alternateName: config.siteTitleAlt || config.title
  // })
  return (
    <Helmet title={config.title}>
      {/* General Tags */}
      <meta name="description" content={config.description} />
      {/* <meta name="image" content={config.siteImage} /> */}

      {/* OpenGraph tags */}
      <meta property="og:title" content={config.title} />
      <meta property="og:description" content={config.description} />
      {/* <meta property="og:url" content={config.siteUrl} /> */}
      {/* <meta property="og:image" content={config.siteImage} /> */}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={config.title} />
      <meta name="twitter:description" content={config.description} />
      {/* <meta name="twitter:image" content={config.siteImage} /> */}

      <html lang="en" />
    </Helmet>
  )
}

export default SEO

import React from 'react'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { shimmerOnHover } from '../../../styles/mixins'

interface IBtnProps {
  drawer: boolean
}

const Button: any = styled('div')`
  ${shimmerOnHover(1.5)};
  background: transparent;
  border: none;
  cursor: pointer;
  text-align: center;
  margin: ${({ drawer }: IBtnProps) => (drawer ? '40px auto' : 'auto 10px')};
  text-decoration: none;
  color: white;
  text-transform: uppercase;
  position: relative;
  &:hover {
    text-decoration: none;
  }
`

interface Props {
  children: string
  onClick?: any
}

const NavButton: React.FC<Props> = ({ children, onClick: _onClick }) => {
  const onClick = _onClick || (() => navigate(`/#${children}`))
  return <Button {...{ onClick }}>{children}</Button>
}

export default NavButton

import * as React from 'react'
import styled from '@emotion/styled'

const StyledLayoutRoot = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Lato;
  font-weight: bolder;
  background-color: #fffbf8;
  * {
    font-family: Arvo;
    font-weight: 900;
  }
`

interface ILayoutRootProps {
  className?: string
}

const LayoutRoot: React.FC<ILayoutRootProps> = ({ children, className }) => (
  <StyledLayoutRoot className={className}>{children}</StyledLayoutRoot>
)

export default LayoutRoot

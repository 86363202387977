import React from 'react'
import styled from '@emotion/styled'
import { FaInstagram } from 'react-icons/fa'
import NavButton from './NavButton'
import { openInstagram, openEtsy } from '../../../utils'
import Burger from './Burger'
import { SHOP_LINK, ABOUT_LINK } from '../../../constants'

const Wrapper: any = styled('div')`
  display: flex;
  flex-direction: row;
  font-size: 1.2rem;
  flex-basis: auto;
  justify-content: space-between;
  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin: auto 7.5px;
    color: white;
    &:hover {
      cursor: pointer;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: 640px) {
    display: none;
  }
`

interface Props {
  drawerIsActive: boolean
  toggle: () => void
}

const Nav: React.FC<Props> = ({ drawerIsActive, toggle }) => (
  <>
    <Wrapper key={0}>
      <FaInstagram onClick={openInstagram} />
      <NavButton onClick={openEtsy}>{SHOP_LINK}</NavButton>
      <NavButton>Design</NavButton>
      <NavButton>Illustration</NavButton>
      <NavButton>{ABOUT_LINK}</NavButton>
    </Wrapper>
    <Burger isActive={drawerIsActive} {...{ toggle }} key={1} />
  </>
)

export default Nav

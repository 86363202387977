import * as React from 'react'
import Link from 'gatsby-link'
import styled from '@emotion/styled'
import Nav from './Nav'
import { shimmerOnHover } from '../../styles/mixins'
import MobileNav from './MobileNav'
import { HEADER_TEXT } from '../../constants'
interface Props {
  color: string
}

interface State {
  drawerIsActive: boolean
}

type IWrapperProps = Props & State

const Wrapper = styled('div')`
  ${(props: IWrapperProps) =>
    !props.drawerIsActive &&
    `
      background-color: transparent;
      mix-blend-mode: difference;
  `};
  position: fixed;
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  z-index: 9998;
  h1 {
    margin: 0;
    text-transform: uppercase;
    font-size: 1.7rem;
    grid-area: title;
    color: ${(props: IWrapperProps) => (props.drawerIsActive ? '#b7f9ff' : 'white')};
    text-decoration: none;
    cursor: pointer;
    @media (min-width: 350px) {
      font-size: 2rem;
    }
    @media (min-width: 640px) {
      ${shimmerOnHover(2)};
    }
  }
`
const titleStyles = {
  textDecoration: 'none',
  cursor: 'pointer',
  width: 'fit-content'
}

class Header extends React.Component<Props, State> {
  public state = {
    drawerIsActive: false
  }

  public toggleDrawer = (): void => {
    this.setState({ drawerIsActive: !this.state.drawerIsActive })
  }

  public closeDrawer = () => this.state.drawerIsActive && this.toggleDrawer()

  render() {
    const { color } = this.props
    const { drawerIsActive } = this.state
    return [
      <MobileNav key={0} toggle={this.toggleDrawer} isOpen={drawerIsActive} />,
      <Wrapper key={1} onClick={this.closeDrawer} {...{ color, drawerIsActive }}>
        <Link to="/" style={titleStyles}>
          <h1>{HEADER_TEXT}</h1>
        </Link>
        <Nav {...{ drawerIsActive }} toggle={this.toggleDrawer} />
      </Wrapper>
    ]
  }
}

export default Header

import * as React from 'react'
import styled from '@emotion/styled'
import { COPYRIGHT_TEXT } from '../constants'

interface Props {
  padding?: boolean
}

const Footer = styled('p')`
  text-align: center;
  bottom: 0px;
  left: 0;
  width: 100%;
  font-size: 12px;
  margin: 0;
  padding: ${(props: Props) => (props.padding ? '20px' : 0)} 0;
`

const Copyright: React.FC<Props> = ({ padding }) => <Footer {...{ padding }}>&copy; {COPYRIGHT_TEXT}</Footer>

export default Copyright
